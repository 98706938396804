import { useEffect } from 'react';
import './styles/site.scss';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { Routes, Route, useNavigate, useLocation } from 'react-router';
import { AppTitle } from './components/AppTitle';
import Header from './components/layout/Header';
import Layout from './components/layout/Layout';
import Notifications from './components/Notifications';
import { DEFAULT_LANG } from './consts/consts';
import { defaultLocale, translations } from './intl';
import ROUTES from './routes/routes';
import { selectLanguage, selectCurrentUser } from './store/auth/authSlice';

const App = () => {
  const langSelector = useSelector(selectLanguage) ?? DEFAULT_LANG.EN;
  const userLogin = useSelector(selectCurrentUser);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (userLogin !== null) {
      if (location.pathname === '/finished') {
        navigate(ROUTES.Finished.path + location.search);
      } else if (location.pathname === '/invitation') {
        navigate(ROUTES.invitation.path + location.search);
      } else {
        navigate(ROUTES.Dashboard.path);
      }
    } else if (location.pathname === '/sendPassword') {
      navigate(ROUTES.sendPassword.path);
    } else if (location.pathname === '/invitation') {
      navigate(ROUTES.invitation.path + location.search);
    } else if (location.pathname === '/newPassword') {
      navigate(ROUTES.newPassword.path + location.search);
    } else {
      navigate(ROUTES.login.path);
    }
  }, [userLogin, location.pathname, navigate]);

  return (
    <IntlProvider locale={langSelector} defaultLocale={defaultLocale} messages={translations[langSelector]}>
      <AppTitle />
      <div className="app">
        <header className="app-header">
          <Header user={userLogin} />
        </header>
        <Layout test={userLogin !== null}>
          <Routes>
            {Object.entries(ROUTES).map(([key, { path, component: RouteComponent }]) => (
              <Route path={path} element={RouteComponent} key={key} />
            ))}
          </Routes>
        </Layout>
      </div>
      <Notifications />
    </IntlProvider>
  );
};

export default App;
