import { FormattedMessage } from 'react-intl';
import { messages } from '../../intl';
import Login from './Login';

const SendPassword = () => {
  return (
    <Login
      displayEshop={false}
      headerText={<FormattedMessage {...messages.loginSendPassword} />}
      textDesc={<FormattedMessage {...messages.loginEnterEmail} />}
      loginButtons={false}
      email={true}
      password={false}
    />
  );
};

export default SendPassword;
