import { API, API_SEGMENT } from '../../consts/consts';
import { PROFILE } from '../../consts/RTK';
import { apiSlice } from '../api/apiSlice';

export const profileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [PROFILE.SENDMESSAGE.ENDPOINT]: builder.mutation({
      query: (message) => ({
        url: API[API_SEGMENT.PROFILE].SEND_MESSAGE,
        method: 'POST',
        body: message,
      }),
    }),
    [PROFILE.SWITCHORGANIZATION.ENDPOINT]: builder.mutation({
      query: (IdOrganization) => ({
        url: `${API[API_SEGMENT.PROFILE].SWITCH_ORGANIZATION + '?idOrganization=' + IdOrganization}`,
        method: 'POST',
      }),
      invalidatesTags: ['tests'],
    }),
    [PROFILE.GETORGANIZATIONS.ENDPOINT]: builder.query({
      query: () => ({
        url: `${API[API_SEGMENT.PROFILE].GET_ORGANIZATIONS}`,
        method: 'GET',
      }),
      invalidatesTags: ['tests', 'login'],
    }),
  }),
});

export const endpoints = profileApiSlice.endpoints;
