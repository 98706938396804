import React from 'react';
import logo from '../../images/scio.svg';
import Language from './Language';
import Organizations from './Organizations';
import ProfileMenu from './ProfileMenu';
import './Header.scss';

const Header = (props) => {
  const profileMenu = props.user ? <ProfileMenu /> : '';
  const switchOrgs = props.user ? <Organizations /> : '';

  return (
    <nav className="navbar navbar-expand-sm navbar-toggleable-sm ">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img src={logo} alt="logo Scio" className="nav-brand-logo" />
        </a>

        <div className="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse">
          <ul className="navbar-nav">
            {switchOrgs}
            {profileMenu}
            <Language />
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
