import { defineMessages } from 'react-intl';

export default defineMessages({
  appTitle: {
    id: 'global.appTitle',
    defaultMessage: 'Profile | ScioLink Cloud',
  },
  languageCzech: {
    id: 'global.languageCzech',
    defaultMessage: 'Czech',
  },
  languageSlovakia: {
    id: 'global.languageSlovakia',
    defaultMessage: 'Slovak',
  },
  languageEnglish: {
    id: 'global.languageEnglish',
    defaultMessage: 'English',
  },
  loginSendPassword: {
    id: 'global.loginSendPassword',
    defaultMessage: 'Send new password',
  },
  loginEnterEmail: {
    id: 'global.loginEnterEmail',
    defaultMessage: 'Enter email',
  },
  login: {
    id: 'global.login',
    defaultMessage: 'Login',
  },
  loginButton: {
    id: 'global.loginButton',
    defaultMessage: 'Login',
  },
  backToLoginButton: {
    id: 'global.backToLoginButton',
    defaultMessage: 'Back to login',
  },
  logout: {
    id: 'global.logout',
    defaultMessage: 'Logout',
  },
  loginTextDesc: {
    id: 'global.loginTextDesc',
    defaultMessage: 'Application for proctoring testing',
  },
  loginInvalidUser: {
    id: 'global.loginInvalidUser',
    defaultMessage: 'Invalid user',
  },
  loginUnauthorized: {
    id: 'global.loginUnauthorized',
    defaultMessage: 'Unauthorized',
  },
  loginFailed: {
    id: 'global.loginFailed',
    defaultMessage: 'Login failed',
  },
  loginInvalidPassword: {
    id: 'global.loginInvalidPassword',
    defaultMessage: 'Invalid password',
  },
  forgottenPassword: {
    id: 'global.forgottenPassword',
    defaultMessage: 'Forgotten password',
  },
  loading: {
    id: 'global.loading',
    defaultMessage: 'Loading...',
  },
  confirmButton: {
    id: 'global.confirmButton',
    defaultMessage: 'Confirm',
  },
  activeExams: {
    id: 'global.activeExams',
    defaultMessage: 'Active exams',
  },
  futureExams: {
    id: 'global.futureExams',
    defaultMessage: 'Future exams',
  },
  passedExams: {
    id: 'global.passedExams',
    defaultMessage: 'Passed exams',
  },
  goToTest: {
    id: 'global.goToTest',
    defaultMessage: 'Go to test',
  },
  results: {
    id: 'global.results',
    defaultMessage: 'Results',
  },
  reset: {
    id: 'global.reset',
    defaultMessage: 'reset',
  },
  examEnd: {
    id: 'global.examEnd',
    defaultMessage: 'End of the exam',
  },
  examEndTextSuccess: {
    id: 'global.examEndTextSuccess',
    defaultMessage: 'The exam has ended. Thank you for completing it.',
  },
  examEndTextError: {
    id: 'global.examEndTextError',
    defaultMessage: 'An error occurred while finishing exam...',
  },
  examEnterTextError: {
    id: 'global.examEnterTextError',
    defaultMessage: 'An error occurred while entering exam...',
  },
  examEndButton: {
    id: 'global.examEndButton',
    defaultMessage: 'Overview of exams',
  },
  examEndProctoring: {
    id: 'global.examEndProctoring',
    defaultMessage: 'Video, screen and sound monitoring has finished. You can close Proctoring Desktop.',
  },
  examEnterError: {
    id: 'global.examEnterError',
    defaultMessage: 'Exam error',
  },
  switchOrganization: {
    id: 'global.switchOrganization',
    defaultMessage: 'Switch organization',
  },
  closeButton: {
    id: 'global.closeButton',
    defaultMessage: 'Close',
  },
  invitation: {
    id: 'global.invitation',
    defaultMessage: 'Welcome to Sciolink',
  },
  invitationText: {
    id: 'global.invitationText',
    defaultMessage: 'You have been invited to join ',
  },
  register: {
    id: 'global.register',
    defaultMessage: 'Register',
  },
  registerError: {
    id: 'global.registerError',
    defaultMessage: 'Registration failed!',
  },
  registerSuccess: {
    id: 'global.registerSuccess',
    defaultMessage: 'Registration was successful!',
  },
  registerTextSuccess: {
    id: 'global.registerTextSuccess',
    defaultMessage: 'You can now login into app.',
  },
  verifyPasswordError: {
    id: 'global.verifyPasswordError',
    defaultMessage: 'Password is not the same!',
  },
  newPasswordHeader: {
    id: 'global.newPasswordHeader',
    defaultMessage: 'Enter new password',
  },
  newPasswordMessage: {
    id: 'global.newPasswordMessage',
    defaultMessage: 'Password was successfully updated!',
  },
  password: {
    id: 'global.password',
    defaultMessage: 'Password',
  },
  verifyPassword: {
    id: 'global.verifyPassword',
    defaultMessage: 'Verify password',
  },
  showPassword: {
    id: 'global.showPassword',
    defaultMessage: 'Show password',
  },
  email: {
    id: 'global.email',
    defaultMessage: 'E-mail',
  },
  noExams: {
    id: 'global.NoExams',
    defaultMessage: 'You have no assigned exams in this organization.',
  },
  emailSent: {
    id: 'global.emailSent',
    defaultMessage: 'Email was sent.',
  },
  dateFormat: {
    id: 'global.dateFormat',
    defaultMessage: 'M/d/yyyy',
  },
  sendMessageTitle: {
    id: 'global.sendMessageTitle',
    defaultMessage: 'Send message to helpdesk',
  },
  sendMessageSuccess: {
    id: 'global.sendMessageSuccess',
    defaultMessage: 'Message sent',
  },
  sendMessageError: {
    id: 'global.sendMessageError',
    defaultMessage: 'Send message failed',
  },
  sendMessageOpenModal: {
    id: 'global.sendMessageOpenModal',
    defaultMessage: 'Contact helpdesk',
  },
  sendMessageSendMessage: {
    id: 'global.sendMessageSendMessage',
    defaultMessage: 'Send message',
  },
  btnStartExam: {
    id: 'global.btnStartExam',
    defaultMessage: 'Start',
  },
  btnContinueExam: {
    id: 'global.btnContinueExam',
    defaultMessage: 'Continue',
  },
  btnFinishExam: {
    id: 'global.btnFinishExam',
    defaultMessage: 'Finish',
  },
  btnFinishedExam: {
    id: 'global.btnFinishedExam',
    defaultMessage: 'Finished',
  },
  btnNotFinishedExam: {
    id: 'global.btnNotFinishedExam',
    defaultMessage: 'Not Finished',
  },
  btnNotStartedExam: {
    id: 'global.btnNotStartedExam',
    defaultMessage: 'Not Started',
  },
  btnEvaluatedExam: {
    id: 'global.btnEvaluatedExam',
    defaultMessage: 'Evaluated',
  },
  formatDateLocale: {
    id: 'global.formatDateLocale',
    defaultMessage: 'en',
  },
  invalidForm: {
    id: 'global.invalidForm',
    defaultMessage: 'Data are invalid.',
  },
});
