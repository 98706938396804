/**
 * Formats date
 *
 * @param {string} date to format
 * @param {string} type of scheme to use
 * @return {string} formated date
 * @example
 * formatDate('2022-03-21T17:35:50.727Z', 's'); => 'Mar 22, 2022'
 */
export const formatDate = (date, type, local) => {
  if (local) {
    date = convertUTCDateToLocalDate(date);
  }
  switch (type) {
    case 'cs':
      return new Date(date)
        .toLocaleTimeString(type, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })
        .replace(/\//g, '.');
    case 'en':
    default:
      return new Date(date).toLocaleTimeString(type, {
        year: 'numeric',
        day: 'numeric',
        month: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
  }
};

const convertUTCDateToLocalDate = (dateString) => {
  const date = new Date(dateString);
  const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
};
