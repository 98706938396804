export const AUTH = {
  LOGIN: {
    ENDPOINT: 'login',
    MUTATION: 'useLoginMutation',
  },
  REFRESH: {
    ENDPOINT: 'refresh',
    QUERY: 'useRefreshQuery',
  },
  SETLANG: {
    ENDPOINT: 'setLang',
    MUTATION: 'useSetLangMutation',
  },
  VERIFYINVITATION: {
    ENDPOINT: 'verifyInvitation',
    QUERY: 'useVerifyInvitationQuery',
  },
  REGISTEREXAMINEE: {
    ENDPOINT: 'registerExaminee',
    MUTATION: 'useRegisterExamineeMutation',
  },
  NEWPASSWORD: {
    ENDPOINT: 'newPassword',
    MUTATION: 'useNewPasswordMutation',
  },
  SENDPASSWORD: {
    ENDPOINT: 'sendPassword',
    MUTATION: 'useSendPasswordMutation',
  },
};

export const EXAM = {
  GET: {
    ENDPOINT: 'getTests',
    QUERY: 'useGetTestsQuery',
  },
  RESET: {
    ENDPOINT: 'resetExam',
    MUTATION: 'useResetExamMutation',
  },
  START: {
    ENDPOINT: 'testStart',
    MUTATION: 'useTestStartMutation',
  },
  GETEXAMINSTANCEINFO: {
    ENDPOINT: 'getExamInstanceInfo',
    QUERY: 'useGetExamInstanceInfoQuery',
  },
};

export const PROFILE = {
  SENDMESSAGE: {
    ENDPOINT: 'sendMessage',
    MUTATION: 'useSendMessageMutation',
  },
  SWITCHORGANIZATION: {
    ENDPOINT: 'switchOrganization',
    MUTATION: 'useSwitchOrganizationMutation',
  },
  GETORGANIZATIONS: {
    ENDPOINT: 'getOrganizations',
    QUERY: 'useGetOrganizationsQuery',
  },
};
