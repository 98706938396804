import Toast from 'react-bootstrap/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { NOTIFICATIONS } from '../consts/consts';
import { getNotification, deleteNotification } from '../store/ui/uiSlice';

const Notifications = () => {
  const dispatch = useDispatch();
  const notification = useSelector(getNotification);
  const delay = notification?.type === NOTIFICATIONS.SUCCESS ? 3000 : 10000;
  const error = notification?.type === NOTIFICATIONS.ERROR ? 'error' : '';
  const message = notification?.message ?? '';
  return (
    <Toast onClose={() => dispatch(deleteNotification())} show={notification !== null} delay={delay} autohide>
      <Toast.Header className={error}>
        <strong className="me-auto">{message}</strong>
      </Toast.Header>
    </Toast>
  );
};

export default Notifications;
