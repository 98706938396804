import { createSlice } from '@reduxjs/toolkit';
import { PROFILE } from '../../consts/RTK';
import { endpoints } from './profileApiSlice';

const initialState = {
  message: null,
  requestId: null,
  idOrganization: null,
  examineeName: '',
};

const profileSlice = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    setOrganization: (state, action) => {
      state.idOrganization = action.payload;
    },
    setExamineeName: (state, action) => {
      state.examineeName = action.payload.data ? action.payload.data : action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(endpoints[PROFILE.SENDMESSAGE.ENDPOINT].matchFulfilled, (state, action) => {
      const { payload } = action;
      state.message = payload;
    });
  },
});

export const { setOrganization, setExamineeName } = profileSlice.actions;
export default profileSlice.reducer;
export const selectResult = (state) => state.requestId.resultData;
export const selectIdOrganization = (state) => state.profile.idOrganization;
export const selectExamineeName = (state) => state.profile.examineeName;
