import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { EXAMINSTANCECONTENT } from '../../consts/consts';
import { EXAM } from '../../consts/RTK';
import { messages } from '../../intl';
import { apiSlice } from '../../store/api/apiSlice';
import { formatDate } from '../../utils';
import ButtonType from './ButtonType';

const ExamInstance = ({ content, active, showModal }) => {
  const disabledStates = [EXAMINSTANCECONTENT.STATE.FINISHED, EXAMINSTANCECONTENT.STATE.EVALUATED];
  const boxClassName = active ? 'box active' : 'box inactive';
  const [resetExam, result] = apiSlice[EXAM.RESET.MUTATION]();
  const [testStart, resultTestStart] = apiSlice[EXAM.START.MUTATION]();
  const [examInstanceState, setExamInstanceState] = useState(content.examInstanceState);
  const [startTestBtnDisabled, setStartTestBtnDisabled] = useState(disabledStates.includes(examInstanceState));
  const [startTestData, setStartTestData] = useState(null);
  const apiError = result?.error?.data?.message ?? null;
  const apiTestStartError = resultTestStart?.error?.data?.message ?? null;
  const navigate = useNavigate();
  const intl = useIntl();

  useEffect(() => {
    if (startTestData) {
      if (startTestData.error) {
        showModal();
      } else {
        window.location.replace(startTestData.data.url);
      }
    }
  }, [examInstanceState, startTestData, startTestBtnDisabled]);

  const handleStart = async (IdExamInstance) => {
    try {
      setStartTestData(await testStart(IdExamInstance));
    } catch (error) {
      console.log(apiTestStartError);
      navigate('/finished');
    }
  };

  const handleResults = () => {
    console.log('todo');
  };

  const handleReset = (IdExamInstance) => {
    resetExam(IdExamInstance);
    console.log(apiError);
    setExamInstanceState(0);
    setStartTestBtnDisabled(false);
  };

  const FormatTimeSpan = (timespan) => {
    if (timespan) {
      const milisecs = timespan.slice(timespan.length - 8, timespan.length);
      if (milisecs === '.0000000') {
        timespan = timespan.slice(0, timespan.length - 8);
      }
      return timespan;
    }
    return '';
  };

  const buttonType = (
    <ButtonType
      examInstanceState={examInstanceState}
      examState={content.state}
      onClick={() => handleStart(content.idExamInstance)}
    />
  );

  const reset =
    content.idExamInstance &&
    disabledStates.includes(examInstanceState) &&
    (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'Development') ? (
      <a href="#!" className="small" onClick={() => handleReset(content.idExamInstance)}>
        <FormattedMessage {...messages.reset} />
      </a>
    ) : (
      ''
    );

  return (
    <div className={boxClassName}>
      <div className="row testpart">
        <div className="col-md-6 pl-4 exam-name">
          <b>{content.examName}</b>
          {'  '}
          {reset}
        </div>
        <div className="col-md-1 text-center">{FormatTimeSpan(content.limit)}</div>
        <div className="col-md-2 text-center">
          {content.opened ? `${formatDate(content.opened, intl.formatMessage(messages.formatDateLocale), true)}` : ''}
          <br />
          {content.closed ? `${formatDate(content.closed, intl.formatMessage(messages.formatDateLocale), true)}` : ''}
        </div>
        <div className="col-md-3 text-center">{buttonType}</div>
      </div>
    </div>
  );
};

export default ExamInstance;
