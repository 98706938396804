import { useIntl } from 'react-intl';
import { messages } from '../../intl';
import LoginInput from '../Inputs/LoginInput';

const InvitationContent = (props) => {
  const intl = useIntl();
  const { invitationData, setPassword, setVerifyPassword } = props;
  return (
    <>
      <div className="form-group row">
        <div className="col-md-12">
          <input
            type="text"
            value={invitationData?.email ?? ''}
            name="username"
            className="form-control"
            id="username"
            placeholder="E-mail"
            disabled="disabled"
          />
          <span className="field-validation-valid" data-valmsg-for="username" data-valmsg-replace="true"></span>
        </div>
      </div>
      {invitationData.setPassword ? (
        <>
          <LoginInput name="password" type="password" id="password" placeholder="Heslo" handleChange={setPassword} />
          <LoginInput
            name="verifyPassword"
            type="password"
            id="verifyPassword"
            placeholder="Potvrdit Heslo"
            handleChange={setVerifyPassword}
          />
        </>
      ) : (
        ''
      )}
      <input type="submit" value={intl.formatMessage(messages.register)} className="btn btn-primary btn-lg btn-block" />
    </>
  );
};

export default InvitationContent;
