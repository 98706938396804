import React from 'react';
import { FormattedMessage } from 'react-intl';
import Dashboard from '../components/dashboard/Dashboard';
import Invitation from '../components/Invitation/Invitation';
import Login from '../components/login/Login';
import NewPassword from '../components/login/NewPassword';
import SendPassword from '../components/login/SendPassword';
import Finished from '../components/test/Finished';
import { FORM } from '../consts/consts';
import { messages } from '../intl';

const ROUTES_PUBLIC_PATH = process.env.ROUTES_PUBLIC_PATH ?? '/';

const ROUTES = {
  login: {
    path: `${ROUTES_PUBLIC_PATH}login`,
    isVisible: () => true,
    component: (
      <Login
        headerText={<FormattedMessage {...messages.login} />}
        textDesc={<FormattedMessage {...messages.loginTextDesc} />}
        loginButtons={true}
        email={true}
        password={true}
        form={FORM.LOGIN}
      />
    ),
  },
  root: {
    path: ROUTES_PUBLIC_PATH,
    isVisible: () => false,
    showBubble: true,
  },
  sendPassword: {
    path: `${ROUTES_PUBLIC_PATH}sendPassword`,
    isVisible: () => true,
    component: <SendPassword />,
  },
  Dashboard: {
    path: `${ROUTES_PUBLIC_PATH}test`,
    component: <Dashboard />,
  },
  Finished: {
    path: `${ROUTES_PUBLIC_PATH}finished`,
    component: <Finished />,
  },
  invitation: {
    path: `${ROUTES_PUBLIC_PATH}invitation`,
    isVisible: () => true,
    component: <Invitation />,
  },
  newPassword: {
    path: `${ROUTES_PUBLIC_PATH}newPassword`,
    isVisible: () => true,
    component: <NewPassword />,
  },
};

export default ROUTES;
