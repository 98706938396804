const PagingButtons = ({ prevClassName, nextClassName, pages, onPageChange }) => {
  return (
    <ul className="pagination">
      <li className={prevClassName}>
        <a
          tabIndex="0"
          role="button"
          aria-disabled="false"
          aria-label="Previous page"
          rel="prev"
          onClick={() => onPageChange(-1)}
        >
          ‹
        </a>
      </li>
      {pages}
      <li className={nextClassName}>
        <a
          tabIndex="0"
          role="button"
          aria-disabled="false"
          aria-label="Next page"
          rel="next"
          onClick={() => onPageChange(0)}
        >
          ›
        </a>
      </li>
    </ul>
  );
};

export default PagingButtons;
