import PagingSwitch from './PagingButtons';

const Paging = ({ pageCount, onPageChange, activePage }) => {
  const pages = [];
  for (let i = 1; i <= pageCount; i++) {
    const label = 'Page ' + i;
    let liClassName = 'btn page';
    if (activePage === i) liClassName += ' active';
    if (i > 1 && i < activePage - 1) {
      pages.push(
        <li className="break" key="prev">
          <a href="#!" onClick={() => onPageChange(activePage - 3)}>
            ...
          </a>
        </li>
      );
      i = activePage - 2;
    } else if (i > activePage + 1 && i < pageCount) {
      pages.push(
        <li className="break" key="next">
          <a href="#!" onClick={() => onPageChange(activePage + 3)}>
            ...
          </a>
        </li>
      );
      i = pageCount - 1;
    } else {
      pages.push(
        <li tabIndex={'li_page_' + i} key={i}>
          <button className={liClassName} tabIndex="0" aria-label={label} onClick={() => onPageChange(i)}>
            {i}
          </button>
        </li>
      );
    }
  }

  const prevClassName = activePage === 1 ? 'btn btn-arrow disabled' : 'btn btn-arrow';
  const nextClassName = activePage === pageCount ? 'btn btn-arrow disabled' : 'btn btn-arrow';
  const pagingSwitch =
    pageCount > 1 ? (
      <PagingSwitch
        prevClassName={prevClassName}
        nextClassName={nextClassName}
        pages={pages}
        onPageChange={onPageChange}
      />
    ) : (
      ''
    );
  return <>{pagingSwitch}</>;
};

export default Paging;
