const cs = {
  'global.appTitle': 'Profil | ScioLink Cloud',
  'global.languageCzech': 'Česky',
  'global.languageSlovakia': 'Slovensky',
  'global.languageEnglish': 'English',
  'global.loginSendPassword': 'Zaslat nové heslo',
  'global.loginEnterEmail': 'Vložte svůj e-mail',
  'global.login': 'Přihlášení',
  'global.loginButton': 'Přihlásit se',
  'global.backToLoginButton': 'Zpět na přihlášení',
  'global.logout': 'Odhlásit se',
  'global.loginTextDesc': 'Aplikace pro testování s pomocí vzdáleného dohledu.',
  'global.loginInvalidUser': 'Špatné přihlašovací údaje',
  'global.loginUnauthorized': 'Unauthorized',
  'global.loginFailed': 'Login failed',
  'global.loginInvalidPassword': 'Špatné heslo',
  'global.forgottenPassword': 'Zapomenuté heslo',
  'global.loading': 'Načítání...',
  'global.confirmButton': 'Potvrdit',
  'global.activeExams': 'Otevřené zkoušky',
  'global.futureExams': 'Naplánované zkoušky',
  'global.passedExams': 'Dokončené zkoušky',
  'global.goToTest': 'Přejít k testu',
  'global.results': 'Výsledky',
  'global.reset': 'reset',
  'global.examEnd': 'Konec zkoušky',
  'global.examEndTextSuccess': 'Zkouška byla ukončena. Děkujeme za vyplnění.',
  'global.examEndTextError': 'Při ukončení zkoušky nastala chyba...',
  'global.examEnterTextError': 'Při otevření zkoušky nastala chyba...',
  'global.examEndButton': 'Přehled zkoušek',
  'global.examEndProctoring':
    'Monitorování videa, zvuku a obrazovky bylo ukončeno. Můžete zavřít aplikaci Proctoring Desktop.',
  'global.examEnterError': 'Chyba zkoušky',
  'global.switchOrganization': 'Změnit organizaci',
  'global.closeButton': 'Zavřít',
  'global.invitation': 'Vítejte ve Sciolinku',
  'global.invitationText': 'Přidejte se ke skupině ',
  'global.register': 'Registrovat',
  'global.registerError': 'Registrace selhala!',
  'global.registerSuccess': 'Registrace úspěšně proběhla!',
  'global.registerTextSuccess': 'Nyní se můžete přihlásit do aplikace.',
  'global.verifyPasswordError': 'Heslo není stejné!',
  'global.newPasswordHeader': 'Zadejte nové heslo',
  'global.newPasswordMessage': 'Heslo bylo úspěšně změněno!',
  'global.password': 'Heslo',
  'global.verifyPassword': 'Potvrdit heslo',
  'global.showPassword': 'Zobrazit heslo',
  'global.email': 'E-mail',
  'global.NoExams': 'V této organizaci nemáte žádné přiřazené zkoušky.',
  'global.emailSent': 'Email byl odeslán.',
  'global.sendMessageTitle': 'Uživatelská podpora',
  'global.sendMessageSuccess': 'Zpráva odeslána',
  'global.sendMessageError': 'Odeslání zprávy se nezdařilo',
  'global.sendMessageOpenModal': 'Zaslat zprávu',
  'global.sendMessageSendMessage': 'Odeslat zprávu',
  'global.dateFormat': 'd.M.yyyy',
  'global.btnStartExam': 'Zahájit',
  'global.btnContinueExam': 'Pokračovat',
  'global.btnFinishExam': 'Dokončit',
  'global.btnFinishedExam': 'Dokončeno',
  'global.btnNotFinishedExam': 'Nedokončeno',
  'global.btnNotStartedExam': 'Nezahájeno',
  'global.btnEvaluatedExam': 'Vyhodnoceno',
  'global.formatDateLocale': 'cs',
  'global.invalidForm': 'Data jsou nevalidní.',
};

export default cs;
