import React, { useState, useRef, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { KEYS, NOTIFICATIONS } from '../../consts/consts';
import { AUTH } from '../../consts/RTK';
import { messages } from '../../intl';
import { apiSlice } from '../../store/api/apiSlice';
import { setNotification } from '../../store/ui/uiSlice';
import RegisterExamineeModal from '../modals/RegisterExamineeModal';
import InvitationContent from './InvitationContent';
import '../login/Login.scss';

const Invitation = () => {
  const token = new URLSearchParams(window.location.search).get('token');
  const [registerExaminee, { isLoading }] = apiSlice[AUTH.REGISTEREXAMINEE.MUTATION]();
  const data = apiSlice[AUTH.VERIFYINVITATION.QUERY](token);
  const apiError = data?.error?.data?.error ?? null;
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [invitationData, setInvitationData] = useState({});
  const [show, setShow] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const errorRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleClose = () => {
    setShow(false);
    navigate('/login');
  };

  const handleErrMsg = (msg, scs) => {
    setRegistrationSuccess(scs);
    setErrMsg(msg);
  };

  useEffect(() => {
    if (apiError) {
      handleErrMsg(apiError, false);
      setShow(true);
    } else if (data?.data) {
      setInvitationData(data.data);
    }
    if (!!errorMessage) {
      dispatch(setNotification({ type: NOTIFICATIONS.ERROR, message: errorMessage }));
    }
  }, [data, errorMessage]);

  const register = async () => {
    const result = await registerExaminee({
      idOrganization: invitationData.idOrganization,
      password: password,
      verifyPassword,
      idExaminee: invitationData.idExaminee,
    });
    if (!result?.error) {
      setErrorMessage('');
      console.log('registered');
      handleErrMsg('', true);
      setShow(true);
    } else if (!result?.error?.status) {
      // isLoading: true until timeout occurs
      setErrorMessage('No Server Response');
    } else if (result?.error?.status === 406) {
      setErrorMessage(intl.formatMessage(messages.verifyPasswordError));
    }
  };

  return (
    <div className="login-box">
      <h3>
        <FormattedMessage {...messages.invitation} />
      </h3>
      <p className="text-description">
        <FormattedMessage {...messages.invitationText} />
        {invitationData.organization}
      </p>
      {isLoading ? (
        <>
          <h3>{<FormattedMessage {...messages.loading} />}</h3>
          <p className="error-message" ref={errorRef} aria-live="assertive">
            {errorMessage}
          </p>
        </>
      ) : (
        <form onSubmit={register}>
          <InvitationContent
            invitationData={invitationData}
            setPassword={setPassword}
            setVerifyPassword={setVerifyPassword}
          />
        </form>
      )}
      <RegisterExamineeModal show={show} handleClose={handleClose} success={registrationSuccess} message={errMsg} />
    </div>
  );
};

export default Invitation;
