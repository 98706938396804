import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notification: null,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState: initialState,
  reducers: {
    deleteNotification: (state) => {
      state.notification = null;
    },
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
  },
});

export const { deleteNotification, setNotification } = uiSlice.actions;
export default uiSlice.reducer;
export const getNotification = (state) => state.ui.notification;
