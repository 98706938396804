import React from 'react';
import LanguageSelector from './LanguageSelector';

const Language = () => {
  return (
    <li className="nav-item">
      <div className="language-selector">
        <LanguageSelector />
      </div>
    </li>
  );
};

export default Language;
