import { API, API_SEGMENT } from '../../consts/consts';
import { EXAM } from '../../consts/RTK';
import { apiSlice } from '../api/apiSlice';

export const testApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [EXAM.GET.ENDPOINT]: builder.query({
      query: () => `${API[API_SEGMENT.TEST].GET_TESTS}`,
      providesTags: ['tests'],
      invalidatesTags: ['login'],
    }),
    [EXAM.RESET.ENDPOINT]: builder.mutation({
      query: (idExamInstance) => ({
        url: `${API[API_SEGMENT.TEST].RESET_EXAM + '?idExamInstance=' + idExamInstance}`,
        method: 'POST',
      }),
    }),
    [EXAM.START.ENDPOINT]: builder.mutation({
      query: (idExamInstance) => ({
        url: `${API[API_SEGMENT.TEST].TEST_START + '?idExamInstance=' + idExamInstance}`,
        method: 'POST',
      }),
    }),
    [EXAM.GETEXAMINSTANCEINFO.ENDPOINT]: builder.query({
      query: (idExamInstance) => `${API[API_SEGMENT.TEST].GET_EXAMINSTANCEINFO + '?idExamInstance=' + idExamInstance}`,
    }),
  }),
});

export const endpoints = testApiSlice.endpoints;
