import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { PROFILE } from '../../consts/RTK';
import { messages } from '../../intl';
import { apiSlice } from '../../store/api/apiSlice';
import { selectCurrentUser } from '../../store/auth/authSlice';

const SendMessageModal = () => {
  const [show, setShowSendMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [messageSent, setMessageSent] = useState(false);
  const [success, setSuccess] = useState(true);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [requestId, setRequestId] = useState('');
  const messageTitle = <FormattedMessage {...messages.sendMessageTitle} />;
  const user = useSelector(selectCurrentUser);
  const [sendMessageApi] = apiSlice[PROFILE.SENDMESSAGE.MUTATION]();
  const handleSendMessageClose = () => setShowSendMessage(false);
  const handleSendMessageShow = () => {
    setShowSendMessage(true);
    setShowSubmitButton(true);
    setMessage('');
    setMessageSent(false);
  };

  const handleSendMessage = async () => {
    console.log('Sending');
    console.log(message);
    const result = await sendMessageApi({ message: message });
    if (!result?.error) {
      setMessageSent(true);
      setSuccess(true);
      setShowSubmitButton(false);
      setRequestId(result.data.requestId);
    } else {
      console.log(result);
      setMessageSent(true);
      setSuccess(false);
    }
  };

  const messageBody = messageSent ? (
    success ? (
      <div className="box info">
        <FormattedMessage {...messages.sendMessageSuccess} />
        <br />
        {requestId}
      </div>
    ) : (
      <div className="box error">
        <FormattedMessage {...messages.sendMessageError} />
      </div>
    )
  ) : (
    <textarea
      cols="40"
      rows="10"
      className="form-control"
      onChange={(e) => setMessage(e.target.value)}
      value={message}
    />
  );

  const submitButton = showSubmitButton && (
    <>
      &nbsp;
      <Button variant="primary" onClick={handleSendMessage}>
        <FormattedMessage {...messages.sendMessageSendMessage} />
      </Button>
    </>
  );

  return (
    <div>
      <Modal show={show} onHide={() => handleSendMessageClose()}>
        <Modal.Body>
          <div className="main-panel pb-0 mt-1">
            <div className="row">
              <div className="col-md-12 text-center">
                <h3>{messageTitle}</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 pt-2 pb-2">{user?.name}</div>
              <div className="col-md-6 pt-2 pb-2 text-right">{user?.email}</div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">{messageBody}</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleSendMessageClose}>
            <FormattedMessage {...messages.closeButton} />
          </Button>
          {submitButton}
        </Modal.Footer>
      </Modal>
      <Button variant="primary" onClick={handleSendMessageShow}>
        <FormattedMessage {...messages.sendMessageOpenModal} />
      </Button>
    </div>
  );
};

export default SendMessageModal;
