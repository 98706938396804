import React from 'react';
import { useCookies } from 'react-cookie';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { COOKIES, DEFAULT_LANG } from '../../consts/consts';
import { AUTH } from '../../consts/RTK';
import logoCZ from '../../images/flag_CZ.png';
import logoEN from '../../images/flag_EN.png';
// import logoSK from '../images/flag_SK.png';
import { messages } from '../../intl';
import { setLanguage, selectLanguage } from '../../store/auth/authSlice';
// eslint-disable-next-line
import { apiSlice } from '../../store/api/apiSlice';
import './LanguageSelector.scss';

const LanguageSelector = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([COOKIES.LANG]);
  const [lang, resultLang] = apiSlice[AUTH.SETLANG.MUTATION]();
  const langSelector = useSelector(selectLanguage) ?? DEFAULT_LANG.EN; // TODO docasne, po dokonceni aplikace odebrat

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    option: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
  };

  const SwitchLanguage = async (value) => {
    lang(value);
    dispatch(setLanguage(value));
  };

  const LANGUAGES = [
    {
      value: 'cs',
      label: (
        <div>
          <img src={logoCZ} alt="flag CZ" title={intl.formatMessage(messages.languageCzech)} />
        </div>
      ),
    },
    // {value: 'sk', label: <div><img src={logoSK} alt='flag SK' title={intl.formatMessage(messages.languageSlovakia)} /></div>}, TODO add localization for Slovakia
    {
      value: 'en',
      label: (
        <div>
          <img src={logoEN} alt="flag EN" title={intl.formatMessage(messages.languageEnglish)} />
        </div>
      ),
    },
  ];

  const language = cookies[COOKIES.LANG] ? cookies[COOKIES.LANG] : langSelector;

  const langDefaultValue = LANGUAGES.filter((content) => content.value === language);

  return (
    <Select
      id="language-selector-box"
      defaultValue={langDefaultValue}
      options={LANGUAGES}
      onChange={(event) => SwitchLanguage(event.value)}
      styles={selectStyles}
    />
  );
};

export default LanguageSelector;
