import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { EXAM_STATE_ARRAY } from '../../consts/consts';

const ButtonType = ({ examInstanceState, examState, onClick }) => {
  const [span, setSpan] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [className, setClassName] = useState('btn btn-outline-primary btn-col btn-inline-block');
  const [disabled, setDisabled] = useState(false);
  const [hideButton, setHideButton] = useState(false);

  useEffect(() => {
    const state = EXAM_STATE_ARRAY[examState][examInstanceState];
    if (!!state.message) setButtonText(<FormattedMessage {...state.message} />);
    if (!!state.disable) setDisabled(state.disable);
    if (!!state.btnClassName) setClassName(state.btnClassName);
    if (!!state.span) setSpan(state.span);
    if (!!state.hideButton) setHideButton(state.hideButton);
  }, []);

  return (
    <>
      {span}
      {hideButton ? (
        ''
      ) : (
        <Button className={className} disabled={disabled} onClick={onClick}>
          {buttonText}
        </Button>
      )}
    </>
  );
};

export default ButtonType;
