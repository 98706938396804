import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { messages } from '../../intl';
import { logOut, selectCurrentUser, selectLanguage } from '../../store/auth/authSlice';
import { selectExamineeName } from '../../store/profile/profileSlice';
import './ProfileMenu.scss';

const ProfileMenu = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const language = useSelector(selectLanguage);
  const examineeName = useSelector(selectExamineeName);
  const navigate = useNavigate();
  const intl = useIntl();

  const getInitials = (currentName) => {
    if (!currentName) currentName = user.name;
    const nameSplit = currentName.split(' ');
    let initials = nameSplit[0].substring(0, 1).toUpperCase();

    if (nameSplit.length > 1) {
      initials += nameSplit[nameSplit.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  };

  const handleLogout = () => {
    dispatch(logOut(language));
    navigate('/login');
  };

  return (
    <li className="nav-item">
      <div id="user-popup-box">
        <div className="initials">{getInitials(examineeName)}</div>
        <div className="user-popup">
          <div className="full-name">{examineeName}</div>
          <div className="delimiter"></div>
          <div className="item">{user.email}</div>
          <div className="item">
            <Moment format={intl.formatMessage(messages.dateFormat)}>{user.dateOfBirth}</Moment>
          </div>
          <div className="delimiter"></div>
          <button type="button" className="btn btn-popup text-center" onClick={handleLogout}>
            {<FormattedMessage {...messages.logout} />}
          </button>
        </div>
      </div>
    </li>
  );
};

export default ProfileMenu;
