import { API, API_SEGMENT } from '../../consts/consts';
import { AUTH } from '../../consts/RTK';
import { apiSlice } from '../api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [AUTH.LOGIN.ENDPOINT]: builder.mutation({
      query: (credentials) => ({
        url: API[API_SEGMENT.LOGIN].LOGIN,
        method: 'POST',
        body: { ...credentials },
      }),
      providesTags: ['login'],
    }),
    [AUTH.REFRESH.ENDPOINT]: builder.query({
      query: () => ({
        url: `${API[API_SEGMENT.LOGIN].REFRESH}`,
        method: 'GET',
      }),
    }),
    [AUTH.SETLANG.ENDPOINT]: builder.mutation({
      query: (lang) => ({
        url: `${API[API_SEGMENT.LOGIN].SET_LANG + '?lang=' + lang}`,
        method: 'POST',
      }),
    }),
    [AUTH.VERIFYINVITATION.ENDPOINT]: builder.query({
      query: (token) => ({
        url: `${API[API_SEGMENT.LOGIN].VERIFY_INVITATION + '?token=' + token}`,
        method: 'GET',
      }),
    }),
    [AUTH.REGISTEREXAMINEE.ENDPOINT]: builder.mutation({
      query: (credentials) => ({
        url: `${API[API_SEGMENT.LOGIN].REGISTER_EXAMINEE}`,
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    [AUTH.NEWPASSWORD.ENDPOINT]: builder.mutation({
      query: (credentials) => ({
        url: `${API[API_SEGMENT.LOGIN].NEW_PASSWORD}`,
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    [AUTH.SENDPASSWORD.ENDPOINT]: builder.mutation({
      query: (email) => ({
        url: `${API[API_SEGMENT.LOGIN].SEND_PASSWORD + '?email=' + email}`,
        method: 'POST',
      }),
    }),
  }),
});

export const endpoints = authApiSlice.endpoints;
