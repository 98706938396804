import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import testSuccess from '../../images/test-success.svg';
import { messages } from '../../intl';

const NewPasswordModal = ({ show, handleClose }) => {
  const intl = useIntl();
  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Body>
        <div className="main-panel mb-3 mt-5">
          <div className="row">
            <div className="col-md-12 text-center mt-5">
              <h3>
                <FormattedMessage {...messages.newPasswordMessage} />
              </h3>
            </div>
            <div className="col-md-12 text-center">
              <img src={testSuccess} alt={intl.formatMessage(messages.newPasswordMessage)} className="mt-5 mb-5" />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          <FormattedMessage {...messages.closeButton} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewPasswordModal;
