import React from 'react';
import LoginForm from './LoginForm';
import './Login.scss';

const Login = (props) => {
  return (
    <div className="login-box">
      <h3>{props.headerText}</h3>
      <p className="text-description">{props.textDesc}</p>
      <LoginForm email={props.email} password={props.password} loginButtons={props.loginButtons} />
    </div>
  );
};

export default Login;
