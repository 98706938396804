import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { EXAM } from '../../consts/RTK';
import testSuccess from '../../images/test-success.svg';
import { messages } from '../../intl';
import { apiSlice } from '../../store/api/apiSlice';

const Finished = () => {
  const navigate = useNavigate();
  const idExamInstance = new URLSearchParams(window.location.search).get('idExamInstance');
  const { data } = apiSlice[EXAM.GETEXAMINSTANCEINFO.QUERY](idExamInstance);
  const [proctoringText, setProctoringText] = useState('');
  const [message, setMessage] = useState('');

  const TestNavigate = () => {
    navigate('/test');
  };

  useEffect(() => {
    if (!!data) {
      setProctoringText(
        data && data.ok && data.resultMessage.messageType === 0 && data.resultMessage.isProctoringAvailable ? (
          <FormattedMessage {...messages.examEndProctoring} />
        ) : (
          ''
        )
      );
      setMessage(data && data.resultMessage.message ? <p>{data.resultMessage.message}</p> : '');
    }
  }, [data]);

  return (
    <div className="container">
      <div className="main-panel mb-3 mt-5">
        <div className="row">
          <div className="col-md-12 text-center mt-5">
            <h3>
              <FormattedMessage {...messages.examEnd} />
            </h3>
            <p>
              <FormattedMessage {...messages.examEndTextSuccess} />
            </p>
            <p>{proctoringText}</p>
          </div>
          <div className="col-md-12 text-center">
            <img src={testSuccess} alt={<FormattedMessage {...messages.examEndTextSuccess} />} className="mt-5 mb-5" />
          </div>
          <div className="col-md-12 text-center">{message}</div>
        </div>
        <hr className="full" />
        <div className="row">
          <div className="col-md-12 text-right">
            <button onClick={() => TestNavigate()} className="btn btn-primary">
              <FormattedMessage {...messages.examEndButton} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finished;
