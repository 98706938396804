import { createSlice } from '@reduxjs/toolkit';
import { AUTH } from '../../consts/RTK';
import { endpoints } from './authApiSlice';

const initialState = {
  currentUser: null,
  loggedIn: false,
  idExaminee: null,
  language: 'en',
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logOut: (state, action) => {
      state.language = action.payload;
      state.currentUser = null;
      state.idExaminee = null;
      state.loggedIn = false;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(endpoints[AUTH.LOGIN.ENDPOINT].matchFulfilled, (state, action) => {
      state.currentUser = action.payload;
      state.idExaminee = action.payload.idExaminee;
      state.loggedIn = true;
    });
  },
});

export const { logOut, setLanguage } = authSlice.actions;
export default authSlice.reducer;
export const selectCurrentUser = (state) => state.auth.currentUser;
export const selectLanguage = (state) => state.auth.language;
export const selectIdExaminee = (state) => state.auth.idExaminee;
