import React, { useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { COOKIES, KEYS, NOTIFICATIONS } from '../../consts/consts';
import { AUTH } from '../../consts/RTK';
import { messages } from '../../intl';
import { apiSlice } from '../../store/api/apiSlice';
import { selectLanguage } from '../../store/auth/authSlice';
import { setExamineeName, setOrganization } from '../../store/profile/profileSlice';
import { setNotification } from '../../store/ui/uiSlice';
import LoginButtons from '../Inputs/LoginButtons';
import './LoginForm.scss';
import LoginInput from '../Inputs/LoginInput';

const LoginForm = (props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cookies] = useCookies([COOKIES.LANG]);
  const [login, { isLoading }] = apiSlice[AUTH.LOGIN.MUTATION]();
  const [sendPassword] = apiSlice[AUTH.SENDPASSWORD.MUTATION]();
  const errorRef = useRef();
  const navigate = useNavigate();
  const langSelector = useSelector(selectLanguage);
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const lang = cookies[COOKIES.LANG] ? cookies[COOKIES.LANG] : langSelector;
      const userData = await login({ username: email, password, lang }).unwrap();
      dispatch(setOrganization(userData.idOrganization));
      dispatch(setExamineeName(userData.name));
      console.log('userData =', userData);
      setEmail('');
      setPassword('');
      setErrorMessage('');
      navigate('/test');
    } catch (error) {
      if (!error?.status) {
        // isLoading: true until timeout occurs
        setErrorMessage('No Server Response');
      } else if (error.status === 400) {
        setErrorMessage(<FormattedMessage {...messages.loginInvalidUser} />);
      } else if (error.status === 401) {
        setErrorMessage(<FormattedMessage {...messages.loginUnauthorized} />);
      } else {
        setErrorMessage(<FormattedMessage {...messages.loginFailed} />);
      }
      errorRef.current.focus();
    } finally {
      e.stopPropagation();
    }
  };

  const handleSendPassword = async (e) => {
    e.preventDefault();
    const result = sendPassword(email);
    if (!result?.error) {
      console.log(result);
      setErrorMessage('');
      dispatch(setNotification({ type: NOTIFICATIONS.SUCCESS, message: intl.formatMessage(messages.emailSent) }));
      navigate('/login');
    } else if (!result?.error?.status) {
      // isLoading: true until timeout occurs
      setErrorMessage('No Server Response');
    } else if (result?.error?.status === 400) {
      console.log(result.error.data.error);
      setErrorMessage(result.error.data.error);
    }
    e.stopPropagation();
  };

  const renderErrorMessage = (
    <p className="error-message" ref={errorRef} aria-live="assertive">
      {errorMessage}
    </p>
  );

  const emailComp = props.email ? (
    <LoginInput
      name="username"
      type="text"
      id="username"
      placeholder={intl.formatMessage(messages.email)}
      handleChange={setEmail}
    />
  ) : null;

  const passwordComp = props.password ? (
    <LoginInput
      name="password"
      type="password"
      id="password"
      placeholder={intl.formatMessage(messages.password)}
      handleChange={setPassword}
    />
  ) : null;

  const buttons = props.loginButtons ? (
    <LoginButtons
      link="/sendPassword"
      linkText={<FormattedMessage {...messages.forgottenPassword} />}
      buttonText={intl.formatMessage(messages.loginButton)}
    />
  ) : (
    <LoginButtons
      link="/"
      linkText={<FormattedMessage {...messages.backToLoginButton} />}
      buttonText={intl.formatMessage(messages.confirmButton)}
    />
  );

  const content = isLoading ? (
    <>
      <h3>{<FormattedMessage {...messages.loading} />}</h3>
      {renderErrorMessage}
    </>
  ) : (
    <form onSubmit={(e) => (props.loginButtons ? handleSubmit(e) : handleSendPassword(e))}>
      {emailComp}
      {passwordComp}
      {renderErrorMessage}
      {buttons}
    </form>
  );

  return content;
};

export default LoginForm;
