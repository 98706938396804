import React, { Component } from 'react';
import logo from '../../images/title-image.svg';

export default class LoginLogoLayout extends Component {
  render() {
    return (
      <div className="col-md-12 text-center">
        <img src={logo} alt="login logo" />
      </div>
    );
  }
}
