import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { EXAM } from '../../consts/RTK';
import { endpoints } from './testApiSlice';

const initialState = {
  resultData: null,
  tests: null,
};
const testSlice = createSlice({
  name: 'test',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addMatcher(endpoints[EXAM.GET.ENDPOINT].matchFulfilled, (state, action) => {
        const { payload } = action;
        state.tests = payload;
      })
      .addMatcher(
        isAnyOf(endpoints[EXAM.START.ENDPOINT].matchFulfilled, endpoints[EXAM.START.ENDPOINT].matchRejected),
        (state, action) => {
          const { payload } = action;
          state.resultData = payload.data ? payload.data : payload;
        }
      );
  },
});

export default testSlice.reducer;
export const selectResult = (state) => state.test.resultData;
export const selectTests = (state) => state.test.tests;
