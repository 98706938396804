import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AUTH, PROFILE } from '../../consts/RTK';
import { messages } from '../../intl';
import { setOrganization, setExamineeName, selectIdOrganization } from '../../store/profile/profileSlice';
// eslint-disable-next-line
import { apiSlice } from '../../store/api/apiSlice';

const Organizations = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [orgId, setOrgId] = useState(useSelector(selectIdOrganization));
  const [organizations, setOrganizations] = useState([]);
  const [idOrgSwitch, result] = apiSlice[PROFILE.SWITCHORGANIZATION.MUTATION]();
  const { data } = apiSlice[PROFILE.GETORGANIZATIONS.QUERY](null, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (!!data) {
      const tempData = [];
      Object.entries(data).map(([key, item]) => {
        tempData[item.idOrganization] = item.name;
      });
      setOrganizations(tempData);
    }
  }, [data]);

  const handleSwitchOrganization = async (IdOrganization) => {
    dispatch(setExamineeName(await idOrgSwitch(IdOrganization)));
    dispatch(setOrganization(IdOrganization));
    setOrgId(IdOrganization);
    navigate('/test');
  };

  return (
    <DropdownButton
      id="dropdown-basic-button"
      onSelect={handleSwitchOrganization}
      title={organizations[orgId] ?? intl.formatMessage(messages.switchOrganization)}
    >
      {Object.entries(organizations).map(([key, content]) => {
        const active = key === orgId ? 'active' : '';
        return (
          <Dropdown.Item eventKey={key} key={key} href="#!" active={active}>
            {content}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
};

export default Organizations;
