import LoginLogoLayout from '../login/LoginLogoLayout';

const Layout = (props) => {
  const baseLayout = props.test ? (
    <div className="container">
      <div className="main-panel">
        <main>{props.children}</main>
      </div>
    </div>
  ) : (
    <div className="container-fluid">
      <div className="row">
        <div className="login-container">
          <div className="container">
            <div className="row">
              <LoginLogoLayout />
            </div>
            <div className="row">
              <div className="col-md-12 h">
                <main>{props.children}</main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return <>{baseLayout}</>;
};

export default Layout;
