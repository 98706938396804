import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TESTCONTENT } from '../../consts/consts';
import { EXAM } from '../../consts/RTK';
import { messages } from '../../intl';
// eslint-disable-next-line
import { apiSlice } from '../../store/api/apiSlice';
import SendMessageModal from '../modals/SendMessageModal';
import StartTestErrorModal from '../modals/StartTestErrorModal';
import ExamInstance from './ExamInstance';
import Paging from './Paging';
import './Dashboard.scss';

const Dashboard = () => {
  const [activeExams, setActiveExams] = useState(<></>);
  const [futureExams, setFutureExams] = useState(<></>);
  const [passedExams, setPassedExams] = useState(<></>);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [emptyOrganization, setEmptyOrganization] = useState(false);
  const [show, setShow] = useState(false);
  const { data } = apiSlice[EXAM.GET.QUERY](null, { refetchOnMountOrArgChange: true });

  const switchPage = (index) => {
    if (index === -1) index = activePage - 1;
    if (index === 0) index = activePage + 1;
    const newOffset = (index - 1) * 10;
    setItemOffset(newOffset);
    setActivePage(index);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (!!data) {
      setActiveExams(
        selectTestData([TESTCONTENT.STATE.ACTIVE, TESTCONTENT.STATE.RESILIENCE], true, data, messages.activeExams)
      );
      setFutureExams(selectTestData([TESTCONTENT.STATE.FUTURE], false, data, messages.futureExams));
      const endOffset = itemOffset + 10;
      const passedData = data.filter((content) => content.state === TESTCONTENT.STATE.PASSED);
      const passedDataSlice = passedData.slice(itemOffset, endOffset);
      setPassedExams(selectTestData([TESTCONTENT.STATE.PASSED], false, passedDataSlice, messages.passedExams));
      setPageCount(Math.ceil(passedData.length / 10));
      setEmptyOrganization(data.length === 0);
    }
  }, [itemOffset, data]);

  const selectTestData = (type, active, items, headerMessage) => {
    const exams =
      items !== undefined && items.length !== 0
        ? items
            .filter((content) =>
              type.some((element) => {
                if (element === content.state) return true;
              })
            )
            .map((filteredContent, key) => (
              <ExamInstance
                content={filteredContent}
                active={active}
                key={`${filteredContent.idTest}/${key}`}
                showModal={handleShow}
              />
            ))
        : '';
    const header =
      exams.length > 0 ? (
        <h3 className="mb-3">
          <FormattedMessage {...headerMessage} />
        </h3>
      ) : (
        ''
      );
    return (
      <>
        {header}
        {exams}
      </>
    );
  };

  const noOrganizationsMessage = emptyOrganization ? (
    <div className="message-box Error">
      <FormattedMessage {...messages.noExams} />
    </div>
  ) : (
    ''
  );

  return (
    <div className="container">
      <div className="main-panel">
        {noOrganizationsMessage}
        {activeExams}
        {futureExams}
        {passedExams}
        <Paging pageRangeDisplayed={3} pageCount={pageCount} onPageChange={switchPage} activePage={activePage} />
        <SendMessageModal />
      </div>
      <StartTestErrorModal show={show} handleClose={handleClose} />
    </div>
  );
};

export default Dashboard;
