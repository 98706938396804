import { FormattedMessage } from 'react-intl';
import { messages } from '../intl';

export const LS_USER = '__user__';

export const API_SEGMENT = {
  LOGIN: 'Login',
  TEST: 'Test',
  PROFILE: 'Profile',
};

export const BASE_URL = {
  LOGIN: `${process.env.REACT_APP_API_BASE_URL}${API_SEGMENT.LOGIN}`,
  TEST: `${process.env.REACT_APP_API_BASE_URL}${API_SEGMENT.TEST}`,
  PROFILE: `${process.env.REACT_APP_API_BASE_URL}${API_SEGMENT.PROFILE}`,
};

export const API = {
  [API_SEGMENT.LOGIN]: {
    LOGIN: `${BASE_URL.LOGIN}`,
    REFRESH: `${BASE_URL.LOGIN}`,
    SET_LANG: `${BASE_URL.LOGIN}/setLang`,
    VERIFY_INVITATION: `${BASE_URL.LOGIN}/verifyInvitation`,
    REGISTER_EXAMINEE: `${BASE_URL.LOGIN}/registerExaminee`,
    NEW_PASSWORD: `${BASE_URL.LOGIN}/newPassword`,
    SEND_PASSWORD: `${BASE_URL.LOGIN}/sendPassword`,
  },
  [API_SEGMENT.TEST]: {
    GET_TESTS: `${BASE_URL.TEST}`,
    RESET_EXAM: `${BASE_URL.TEST}`,
    TEST_START: `${BASE_URL.TEST}/start`,
    GET_EXAMINSTANCEINFO: `${BASE_URL.TEST}/examInstanceInfo`,
  },
  [API_SEGMENT.TESTSTART]: {
    TEST_START: `${BASE_URL.TESTSTART}`,
  },
  [API_SEGMENT.PROFILE]: {
    SEND_MESSAGE: `${BASE_URL.PROFILE}/sendMessage`,
    SWITCH_ORGANIZATION: `${BASE_URL.PROFILE}/switchOrganization`,
    GET_ORGANIZATIONS: `${BASE_URL.PROFILE}/organizationsList`,
  },
};

export const TESTCONTENT = {
  STATE: {
    ACTIVE: 0,
    RESILIENCE: 1,
    FUTURE: 2,
    PASSED: 3,
  },
};

export const EXAMINSTANCECONTENT = {
  STATE: {
    ASSIGNED: 0,
    RUNNING: 1,
    FINISHED: 4,
    EVALUATED: 5,
  },
};

export const COOKIES = {
  LANG: 'profile_language',
};

export const FORM = {
  LOGIN: 'login',
  INVITATION: 'invitation',
};

export const KEYS = {
  ENTER: 'Enter',
};

export const DEFAULT_LANG = {
  EN: 'en',
};

export const NOTIFICATIONS = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
};

const EXAM_INSTANCE_FINISHED = {
  disable: true,
  btnClassName: 'btn-col',
  hideButton: true,
  span: (
    <span className="status missed">
      <FormattedMessage {...messages.btnFinishedExam} />
    </span>
  ),
};

export const EXAM_STATE_ARRAY = {
  [TESTCONTENT.STATE.ACTIVE]: {
    [EXAMINSTANCECONTENT.STATE.ASSIGNED]: {
      message: messages.btnStartExam,
    },
    [EXAMINSTANCECONTENT.STATE.RUNNING]: {
      message: messages.btnContinueExam,
    },
    [EXAMINSTANCECONTENT.STATE.FINISHED]: { EXAM_INSTANCE_FINISHED },
    [EXAMINSTANCECONTENT.STATE.EVALUATED]: {
      disable: true,
      btnClassName: 'btn-col',
      hideButton: true,
      span: (
        <span className="status missed">
          <FormattedMessage {...messages.btnEvaluatedExam} />
        </span>
      ),
    },
  },
  [TESTCONTENT.STATE.RESILIENCE]: {
    [EXAMINSTANCECONTENT.STATE.RUNNING]: {
      message: messages.btnFinishExam,
    },
    [EXAMINSTANCECONTENT.STATE.FINISHED]: { EXAM_INSTANCE_FINISHED },
  },
  [TESTCONTENT.STATE.FUTURE]: {
    [EXAMINSTANCECONTENT.STATE.ASSIGNED]: {
      message: messages.btnStartExam,
      disable: true,
    },
  },
  [TESTCONTENT.STATE.PASSED]: {
    [EXAMINSTANCECONTENT.STATE.ASSIGNED]: {
      disable: true,
      hideButton: true,
      span: (
        <span className="status missed">
          <FormattedMessage {...messages.btnNotStartedExam} />
        </span>
      ),
    },
    [EXAMINSTANCECONTENT.STATE.RUNNING]: {
      disable: true,
      hideButton: true,
      span: (
        <span className="status missed">
          <FormattedMessage {...messages.btnNotFinishedExam} />
        </span>
      ),
    },
    [EXAMINSTANCECONTENT.STATE.FINISHED]: { EXAM_INSTANCE_FINISHED },
    [EXAMINSTANCECONTENT.STATE.EVALUATED]: {
      disable: true,
      btnClassName: 'btn-col',
      hideButton: true,
      span: (
        <span className="status missed">
          <FormattedMessage {...messages.btnEvaluatedExam} />
        </span>
      ),
    },
  },
};
