import React from 'react';
import { Link } from 'react-router-dom';

const LoginButtons = ({ link, linkText, buttonText }) => {
  return (
    <div className="text-center">
      <Link to={link} className="btn btn-link">
        {linkText}
      </Link>
      <input type="submit" value={buttonText} className="btn btn-primary" />
    </div>
  );
};

export default LoginButtons;
