const LoginInput = ({ name, type, id, placeholder, handleChange }) => {
  return (
    <div className="form-group row">
      <div className="col-md-12">
        <input
          type={type}
          name={name}
          className="form-control"
          id={id}
          placeholder={placeholder}
          onChange={(e) => handleChange(e.target.value)}
        />
        <span className="field-validation-valid" data-valmsg-for={name} data-valmsg-replace="true"></span>
      </div>
    </div>
  );
};

export default LoginInput;
