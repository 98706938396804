import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { KEYS } from '../../consts/consts';
import { AUTH } from '../../consts/RTK';
import { messages } from '../../intl';
import { apiSlice } from '../../store/api/apiSlice';
import LoginInput from '../Inputs/LoginInput';
import NewPasswordModal from '../modals/NewPasswordModal';

const NewPassword = () => {
  const token = new URLSearchParams(window.location.search).get('token');
  const [newPassword] = apiSlice[AUTH.NEWPASSWORD.MUTATION]();
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const intl = useIntl();

  const confirmPassword = async (e) => {
    e.preventDefault();
    const result = await newPassword({ token, password, verifyPassword });
    if (!result?.error) {
      console.log(result);
      setErrorMessage('');
      setShow(true);
    } else if (!result?.error?.status) {
      // isLoading: true until timeout occurs
      setErrorMessage('No Server Response');
    } else if (result?.error?.status === 400) {
      console.log(result.error.data.error);
      setErrorMessage(intl.formatMessage(messages.invalidForm));
    }
    e.stopPropagation();
  };

  const handleClose = () => {
    setShow(false);
    navigate('/login');
  };

  const renderErrorMessage = <p className="error-message">{errorMessage}</p>;

  return (
    <div className="login-box">
      <h3>
        <FormattedMessage {...messages.newPasswordHeader} />
      </h3>
      <form onSubmit={(e) => confirmPassword(e)}>
        <LoginInput
          name="password"
          type="password"
          id="password"
          placeholder={intl.formatMessage(messages.password)}
          handleChange={setPassword}
        />
        <LoginInput
          name="verifyPassword"
          type="password"
          id="verifyPassword"
          placeholder={intl.formatMessage(messages.verifyPassword)}
          handleChange={setVerifyPassword}
        />
        {errorMessage ? renderErrorMessage : <></>}
        <input
          type="submit"
          value={intl.formatMessage(messages.confirmButton)}
          className="btn btn-primary btn-lg btn-block"
        />
        <NewPasswordModal show={show} handleClose={handleClose} />
      </form>
    </div>
  );
};

export default NewPassword;
