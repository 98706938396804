import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logOut } from '../auth/authSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    // TODO mozna do budoucna?

    // const token = getState().auth.token
    // if (token) {
    //   headers.set('authorization', `Bearer ${token}`)
    // }
    headers.set('Content-Type', 'application/json');
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401) {
    console.log('sending refresh token');
    // send refresh token to get new access token
    const refreshResult = await baseQuery('/login', api, extraOptions);
    if (refreshResult?.error?.status === 400) {
      console.log(refreshResult.error.data.error);
      api.dispatch(logOut());
    } else {
      // retry the original query with new token
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});

export const endpoints = apiSlice.endpoints;
