import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import testError from '../../images/test-error.svg';
import { messages } from '../../intl';
import { selectResult } from '../../store/test/testSlice';
import '../test/Finished.scss';

const StartTestErrorModal = ({ show, handleClose }) => {
  const intl = useIntl();
  const data = useSelector(selectResult);
  const [resultMessage, setResultMessage] = useState(null);

  useEffect(() => {
    if (data) {
      setResultMessage(data.error ? data.error : data.result.resultMessage.message);
    }
  }, [data]);

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Body>
        <div className="main-panel mb-3 mt-5">
          <div className="row">
            <div className="col-md-12 text-center mt-5">
              <h3>
                <FormattedMessage {...messages.examEnterError} />
              </h3>
              <p>
                <FormattedMessage {...messages.examEnterTextError} />
              </p>
            </div>
            <div className="col-md-12 text-center">
              <img src={testError} alt={intl.formatMessage(messages.examEnterTextError)} className="mt-5 mb-5" />
            </div>
            <div className="col-md-12 text-center">
              <div className="message-box box error">{resultMessage}</div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          <FormattedMessage {...messages.closeButton} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StartTestErrorModal;
